<template>
	<div class="app">
		<metainfo>
			<template v-slot:title="{ content }">{{ content ? `${content} | Spartfin | Алексей Горынь | Портфолио разработчика` : `Spartfin | Алексей Горынь | Портфолио разработчика` }}</template>
		</metainfo>

		<nav-bar :navBar="data.navBar"></nav-bar>

		<div class="app__content container">
			<router-view></router-view>
		</div>
	</div>
</template>

<script setup>
import navBar from "@/components/NavBar.vue";
import axios from "axios";
import { ref } from 'vue';
import { useMeta } from 'vue-meta'
;
const data = ref({
	navBar: {}
});

useMeta({
	/*script: [
		{
			type: "text/javascript",
			innerHTML : `var t=document.createElement("script");t.type="text/javascript",t.async=!0,t.src='https://vk.com/js/api/openapi.js?173',t.onload=function(){VK.Retargeting.Init("VK-RTRG-1891633-4m8Ob"),VK.Retargeting.Hit()},document.head.appendChild(t)`
		},
		{
			type: "text/javascript",
			innerHTML : `var _tmr = window._tmr || (window._tmr = []);
				_tmr.push({id: "3541651", type: "pageView", start: (new Date()).getTime()});
				(function (d, w, id) {
				if (d.getElementById(id)) return;
				var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
				ts.src = "https://top-fwz1.mail.ru/js/code.js";
				var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
				if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
				})(document, window, "tmr-code");`
		}
	],
	noscript: [
		{
			innerHTML : `<img src="https://vk.com/rtrg?p=VK-RTRG-1891633-4m8Ob" style="position:fixed; left:-999px;" alt=""/>`
		},
		{
			innerHTML : `<div><img src="https://top-fwz1.mail.ru/counter?id=3541651;js=na" style="position:absolute;left:-9999px;" alt="Top.Mail.Ru" /></div>`
		}
	],*/
});

const fetch = async () => {
	try {
		const responce = await axios.get('./json/app.json');
		data.value = responce.data;
	} catch (error) {
		alert('Ошибка загрузки data app')
	} finally {
	}
};
fetch();
</script>

<style lang="scss" scoped>
.app {
	position: relative;
	display: flex;
	width: 100%;
	max-width: 1440px;
	height: auto;
	margin: 0 auto;

	&__content {
		width: 100%;
		margin: 36px 0;

		@include mq(1023) {
			margin: 28px 0;
		}

		@include mq(767) {
			margin: 16px 0;
		}
	}
}
</style>
