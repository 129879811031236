<template>
	<section class="environment">
		<my-loader v-if="!isLoading" />

		<h1 class="environment__title">Окружение web страницы</h1>

		<span class="environment__text"></span>

		<ul class="environment__list">
			<li class="environment__item">
				<span class="color-red">Os:</span>
				<span class="color-blue">{{ os }}</span>
			</li>
			<li class="environment__item">
				<span class="color-red">Браузер:</span>
				<span class="color-blue">{{ getBrowserVersion }}</span>
			</li>
			<li class="environment__item">
				<span class="color-red">Устройство:</span>
				<span class="color-blue">{{ myDevice }}</span>
			</li>
			<li class="environment__item">
				<span class="color-red">UserAgent:</span>
				<span class="color-blue">{{ userAgent }}</span>
			</li>
			<li class="environment__item">
				<span class="color-red">Ширина:</span>
				<span class="color-blue">{{ width }}px</span>
			</li>
			<li class="environment__item">
				<span class="color-red">Высота:</span>
				<span class="color-blue">{{ height }}px</span>
			</li>
			<li class="environment__item">
				<span class="color-red">Tg App:</span>
				<span class="color-blue">{{ tgApp }}</span>
			</li>
			<li class="environment__item">
				<span class="color-red">Tg Init:</span>
				<span class="color-blue">{{ safe ? safe : 'Tg Init false' }}</span>
			</li>
		</ul>
	</section>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useMeta } from 'vue-meta';
import { updateWidth } from "@/plugins/windowWidth.js";

const { windowWidth } = updateWidth();
const isLoading = ref(true);
const os = navigator.platform;
const userAgent = navigator.userAgent;
const isIOS = /iPad|iPhone|iPod/.test(userAgent);
const isAndroid = /Android/.test(userAgent);
const width = ref(window.innerWidth);
const height = ref(window.innerHeight);
const tg = window.Telegram;
const safe = window.Telegram.WebApp.initDataUnsafe.user;

const myBrowser = computed(() => {
	if ((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
		return 'Opera';
	} else if (navigator.userAgent.indexOf("Chrome") != -1 ){
		return 'Chrome';
	} else if (navigator.userAgent.indexOf("Safari") != -1){
		return 'Safari';
	} else if (navigator.userAgent.indexOf("Firefox") != -1 ){
		return 'Firefox';
	} else if ((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )){
		return 'IE';
	} else {
		return 'Unknown';
	}
});

const myDevice = computed(() => {
	if (userAgent.match(/iPhone/i)) {
		return 'iPhone';
	} else if (userAgent.match(/iPad/i)) {
		return 'iPad';
	} else if (userAgent.match(/iPod/i)) {
		return 'iPod';
	} else if (userAgent.match(/Android/i)) {
		return 'Android';
	} else {
		return 'Unknown';
	}
});

const getBrowserVersion = computed(() => {
	const browserName = userAgent.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

	if (/trident/i.test(browserName[0])) {
		const rv = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
		return `IE ${rv[1] || ''}`;
	}

	const version = browserName[2] ? browserName[2] : null;
	const browser = browserName[1] ? browserName[1] : null;

	if (browser === null || version === null) {
		return 'Unknown Browser';
	}

	return `${browser} ${version}`;
})

const tgApp = computed(() => {
	const tgApp = window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user ? true : false;

	if(tgApp) {
		return 'Открыто в Telegram App';
	} else {
		return 'Открыто не в Telegram App';
	}
})

const onResize = () => {
	width.value = window.innerWidth;
	height.value = window.innerHeight;
};

onMounted(() => {
	window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
	window.removeEventListener('resize', onResize);
});

useMeta({
	title: 'Разработка приложений на vue 3 | Spartfin | Алексей Горынь | Портфолио разработчика | Окружение web страницы | Информация о браузере',
	link: [{ rel: "canonical", href: `https://spartfin.ru/blog` }],
	description: 'Оказание услуг по разработке приложений. Разработка приложений на vue 3 | Spartfin | Алексей Горынь | Портфолио разработчика | Окружение web страницы | Информация о браузере'
});
</script>

<style lang="scss">
.environment {
	&__title {
		font-size: 36px;
		font-weight: 400;
		text-align: center;
		margin-bottom: 32px;

		@include mq(1023) {
			font-size: 32px;
			margin: 0 100px 28px;
		}

		@include mq(767) {
			font-size: 26px;
			line-height: 1.12;
			margin: 0 32px 20px;
		}
	}

	&__text {
		line-height: 1.5;
		margin-bottom: 24px;

		@include mq(1023) {
			line-height: 1.4;
			margin-bottom: 20px;
		}

		@include mq(767) {
			line-height: 1.3;
			margin-bottom: 16px;
		}
	}

	&__item {
		display: flex;
		margin-bottom: 6px;

		span {
			&:first-child {
				min-width: 110px;
				margin-right: 12px;
			}
		}
	}
}
</style>
