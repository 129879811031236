<template>
	<section class="orbitxch">
		<h1 class="orbitxch__title">Стратегия Orbitxch</h1>

		<ul class="orbitxch__list">
			<li class="orbitxch__card done" :class="card.cardClass" v-for="(card, i) in planAmountDays" :key="i">
				<div class="orbitxch__content">
					<span class="">День:</span>

					<span class="">План:</span>

					<span class="">Дата:</span>
				</div>

				<div class="orbitxch__content">
					<span class="color-red">{{ i + 1 }}</span>

					<span class="color-blue">{{ planArr[i] }}&#8364;</span>

					<span class="orbitxch__date color-green">{{ dates[i].toLocaleDateString("ru") }}</span>
				</div>
			</li>
		</ul>
	</section>
</template>

<script setup>
import { ref } from 'vue'
import { useMeta } from 'vue-meta'

const planArr = ref([]);
const planAmountDays = ref(30);
const planCoefficientDay = ref(1.11);
const planStartSum = ref(50);
const startDay = new Date('2024-07-30 00:00:00');
const endDay = new Date('2024-08-30 23:59:59');
const dates = ref([]);

const getDates = (startDate, endDate) => {
	const date = new Date(startDate.getTime());

	date.setDate(date.getDate() + 1);

	while (date < endDate) {
		dates.value.push(new Date(date));
		date.setDate(date.getDate() + 1);
	}
}
getDates(startDay, endDay);

planArr.value.push(Math.ceil(planStartSum.value * planCoefficientDay.value));

for (let i = 0; i < planAmountDays.value; i++) {
	planArr.value[i + 1] = Math.ceil(planArr.value[i] * planCoefficientDay.value);
}

useMeta({
	title: 'Разработка приложений на vue 3 | Orbitxch | Spartfin | Алексей Горынь | Портфолио разработчика',
	link: [{ rel: "canonical", href: `https://spartfin.ru` }],
	description: 'description", content: "Оказание услуг по разработке приложений. Разработка приложений на vue 3 | Стратегия Orbitxch | Spartfin | Алексей Горынь | Портфолио разработчика'
});
</script>

<style lang="scss" scoped>
.orbitxch {
	margin: 0 0 32px;

	&__title {
		font-size: 48px;
		font-weight: 700;
		text-align: center;
		margin: 24px 0;

		@include mq(1023) {
			font-size: 32px;
		}

		@include mq(767) {
			font-size: 26px;
			line-height: 1.12;
			margin: 20px 0;
		}
	}

	&__list {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 10px;

		@include mq(1380) {
			grid-template-columns: repeat(4, 1fr);
		}

		@include mq(1200) {
			grid-template-columns: repeat(3, 1fr);
		}

		@include mq(1023) {
			gap: 8px;
		}

		@include mq(767) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include mq(420) {
			grid-template-columns: repeat(1, 1fr);
		}
	}

	&__card {
		display: flex;
		background: linear-gradient(21deg, #dd03e4, #5611ec);
		border-radius: 12px;
		transition: 0.3s;
		cursor: pointer;

		&:hover {
			box-shadow: 8px 8px 10px $box_shadow;
			transform: scale(1.05);

			@include mq(1023) {
				box-shadow: none;
				transform: scale(1);
			}
		}

		&.done {
			background: linear-gradient(85deg, #fb63f9, #c2e534);
		}
	}

	&__content {
		display: flex;
		flex-direction: column;
		margin: 12px;
	}
}</style>
